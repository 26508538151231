import React, { useRef, useLayoutEffect } from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Text } from '@/components/Text'
import { Wrapper, StyledDivider } from './styled'
import { MobileFooter } from '@/layouts/styled'
import MobileBack from '@/components/MobileBack'
import gsap from 'gsap'

const PlanetPage: React.FC = () => {
  const { t } = useTranslation()
  const dividerRef = useRef(null)

  useLayoutEffect(() => {
    const tl = gsap.timeline({})
    tl.fromTo(
      dividerRef.current,
      1.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  })
  return (
    <>
      <Page
        content={
          <PageWrapper>
            <Wrapper>
              <Text
                title={t('dna.join_toggle1_title', {
                  defaultValue: 'WE EMBRACE EXTRAORDINARITY',
                })}
                text={t('dna.join_toggle1_body', {
                  defaultValue:
                    'We are creative problem-solvers. We are curious and we use our talent to experiment and to test new things, to go at it with ambition and fierceness. We have the courage to bring up unconventional thoughts. We truly understand that creativity is the path to finding new possibilities, to innovation. Together, we discover new ways to do banking, to bring new solutions to the market. We change the industry.',
                })}
              />
              <StyledDivider ref={dividerRef} />
            </Wrapper>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
          isSubPage: true,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/our-dna/values"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default PlanetPage
